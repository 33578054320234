import React from 'react';
import NavigationMobile from './NavigationMobile';
import NavigationDesktop from './NavigationDesktop';
import { CalendarDays, Phone, User } from 'lucide-react';
import type { Route } from './Navigation.types';
import { useTranslation } from 'react-i18next';

const NavBarNavigationMenu = () => {
  const { t } = useTranslation('navigation');
  const routes: {
    mainRoutes: Route[];
  } = {
    mainRoutes: [
      {
        icon: <CalendarDays />,
        title: t('Reservations.Bookings'),
        href: '/bookings',
        description: t('myBookingsDescription')
      },
      {
        icon: <User />,
        title: t('Profile'),
        href: '/profile'
      },
      {
        icon: <Phone />,
        title: t('Contact'),
        href: '/contact'
      }
    ]
  };

  return (
    <>
      <NavigationMobile routes={routes.mainRoutes} />
      <NavigationDesktop routes={routes.mainRoutes} />
    </>
  );
};

export default NavBarNavigationMenu;
