import type { StoreApi } from 'zustand';
import type { RootStore } from '@/stores/root-store.types';

export const createStepperSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  currentStep: 1,
  organizationId: '',
  purchaseCompleted: false,
  setOrganizationId: (organizationId: string) => set({ organizationId }),
  addStep: () =>
    set((state) => ({
      currentStep: state.currentStep === 4 ? 4 : state.currentStep + 1
    })),
  removeStep: () =>
    set((state) => ({
      currentStep: state.currentStep === 1 ? 1 : state.currentStep - 1
    })),
  setStep: (step: number) =>
    set((state) => ({ currentStep: (state.currentStep = step) })),
  setPurchaseCompleted: (purchaseCompleted: boolean) =>
    set(() => ({ purchaseCompleted }))
});
