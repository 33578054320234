import { Button } from '@/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle
} from '@/components/ui/navigation-menu';
import useActiveLink from '@/hooks/useActiveLink';
import useMixPanel from '@/hooks/useMixPanel';
import { cn } from '@/lib/utils';
import { Mixpanel } from '@/utils/mixPanel';
import Link from 'next/link';
import type { ReactNode } from 'react';
import { UserNav } from '../NavBar/UserNav';
import type { Route } from './Navigation.types';

const ListItem = ({
  className,
  title,
  href,
  children,
  active
}: {
  className?: string;
  title?: string;
  href?: string;
  children?: ReactNode;
  active?: boolean;
}) => {
  const Mixpanel = useMixPanel();

  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          href={href ?? ''}
          passHref
          onClick={() =>
            // biome-ignore lint/style/useTemplate: <explanation>
            Mixpanel.track('press ' + title, {
              screen: 'nav bar desktop'
            })
          }
        >
          <div
            className={cn(
              `block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground ${
                active ? 'bg-primary/30' : ''
              }`,
              className
            )}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </div>
        </Link>
      </NavigationMenuLink>
    </li>
  );
};
ListItem.displayName = 'ListItem';

const NavigationDesktop = ({ routes }: { routes: Route[] }) => {
  const { routeIsActive } = useActiveLink();

  return (
    <NavigationMenu className="hidden lg:block">
      <NavigationMenuList className="m-0">
        {routes
          .filter((route) => route.href !== '/profile')
          .map((route) => {
            return (
              <NavigationMenuItem key={route.title}>
                {route.subroutes ? (
                  <>
                    <NavigationMenuTrigger
                      className={`${
                        routeIsActive(route) ? 'bg-primary/30' : ' '
                      }  px-4 py-2`}
                    >
                      {route.title}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] list-none ">
                        {route.subroutes.map((subroute) => (
                          <ListItem
                            key={subroute.title}
                            title={subroute.title}
                            href={subroute.href}
                            active={routeIsActive(subroute)}
                          >
                            {subroute.description}
                          </ListItem>
                        ))}
                      </ul>
                    </NavigationMenuContent>
                  </>
                ) : (
                  <NavigationMenuLink
                    className={`${navigationMenuTriggerStyle()} ${
                      routeIsActive(route) ? 'bg-primary/30' : ' '
                    }`}
                  >
                    <Link
                      href={route?.href ? route.href : ''}
                      legacyBehavior
                      passHref
                    >
                      <Button
                        onClick={() =>
                          Mixpanel.track(`press ${route.title}`, {
                            screen: 'nav bar desktop'
                          })
                        }
                        variant={'ghost'}
                      >
                        {route.title}
                      </Button>
                    </Link>
                  </NavigationMenuLink>
                )}
              </NavigationMenuItem>
            );
          })}
        <NavigationMenuItem>
          <UserNav
            routes={routes.filter((route) => route.href === '/profile')}
          />
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default NavigationDesktop;
