import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';
import { createHydratateSlice } from './slices/hydratate/hydratate-slice';
import type { RootStore } from './root-store.types';
import { createSessionSlice } from './slices/session/session-slice';
import { createStepperSlice } from './slices/stepper/stepper-slice';
import { createStepServiceSlice } from './slices/step-service/step-service';
import { createInsuranceModalSlice } from './slices/insurance-modal/insurance-modal';

export const createRootStore = () => {
  return createStore<RootStore>()(
    persist(
      // zustandActions refers to zustand inner methos as set,get,etc...
      //TODO: Remove any
      (...zustandActions): any => ({
        ...createHydratateSlice(...zustandActions),
        ...createSessionSlice(...zustandActions),
        ...createStepperSlice(...zustandActions),
        ...createStepServiceSlice(...zustandActions),
        ...createInsuranceModalSlice(...zustandActions)
      }),
      {
        name: 'root-store',
        onRehydrateStorage: (state) => {
          // optional
          return (state, error) => {
            if (error) {
              console.log('an error happened during hydration', error);
            } else {
              state?.setHasHydrated(true);
            }
          };
        }
      }
    )
  );
};
