import { type ApisauceInstance, create } from 'apisauce';
import { type ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { ConsumerApi } from './consumer-api';
import { ServicesApi } from './services-api';
import { getAccessTokenForHeader } from '@/actions/actions';
import { SaleApi } from './sale-api';
import { UserApi } from './user-api';
import { ReservationApi } from './reservation-api';
import { PaymentApi } from './payment-api';
import { OrganizationApi } from './organization-api';
import { IdentificationApi } from './identification-api';
import { NpsApi } from './nps-api';
/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  token: string | null;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.token = null;

    this.setup();
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json'
      }
    });

    // Interceptor de respuesta para manejar errores 401
    this.apisauce.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (this.token) {
            window.location.href = '/api/auth/logout';
            this.removeToken();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getRequestConfig(): Promise<any> {
    const accessToken = await getAccessTokenForHeader();
    if (!accessToken) {
      this.apisauce.setHeaders({
        Accept: 'application/json'
      });
    } else {
      this.token = accessToken;
      this.apisauce.setHeaders({
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      });
    }
  }

  removeToken() {
    this.token = null;
    this.apisauce.setHeaders({
      Accept: 'application/json'
    });
  }

  setToken(token: string) {
    if (token === '') return;
    this.token = token;

    this.apisauce.setHeaders({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    });
  }
}

const baseApi = new Api();

const api = {
  api: baseApi,
  consumer: new ConsumerApi(baseApi),
  services: new ServicesApi(baseApi),
  sale: new SaleApi(baseApi),
  user: new UserApi(baseApi),
  reservation: new ReservationApi(baseApi),
  payment: new PaymentApi(baseApi),
  organization: new OrganizationApi(baseApi),
  identification: new IdentificationApi(baseApi),
  nps: new NpsApi(baseApi)
};

export default api;
