import type { ApiResponse } from 'apisauce';
import type { Api } from './api';

export class NpsApi {
  readonly api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async sendAnswer(
    idSale: string,
    body: { answers: string[]; consumer: string }
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `nps/answer/${idSale}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async getForm(idSale: string, consumer: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `nps/form/${idSale}?consumer=${consumer}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
